/* eslint-disable @next/next/no-img-element */
import React from 'react';
import LoginNavbar from './login/LoginNavbar';

function LoginLayout({ children }) {
  return (
    <div className="relative w-full min-h-screen bg-gradient-to-r from-[#2E4B9F] to-[#4169DA] font-poppins cursor-default">
      <LoginNavbar />
      <div className="w-full h-full min-h-screen flex items-center justify-center pt-16 md:pt-24">
        {children}
      </div>
      <figure className="absolute bottom-0 left-0 w-full">
        <img alt="Waves" src="/waves.png" />
      </figure>
    </div>
  );
}

export default LoginLayout;
