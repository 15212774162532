/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Head from 'next/head';
import LayoutText from '../../components/auth/LayoutText';
import LoginForm from '../../components/auth/LoginForm';
import LoginLayout from '../../components/layouts/LoginLayout';

export default function SignIn() {
  return (
    <LoginLayout>
      <Head>
        <title>Inicio de sesión | Widú Legal</title>
        <meta
          name="description"
          content="Inicio de sesión: En WIDÚ LEGAL podrás encontrar documentos legales en línea, válidos y confiables en toda Latinoamérica ¡Solicita tu asesoría hoy!"
        />
        <meta property="og:title" content="Inicio de sesión | Widú Legal" />
        <meta
          property="og:description"
          content="Inicio de sesión: En WIDÚ LEGAL podrás encontrar documentos legales en línea, válidos y confiables en toda Latinoamérica ¡Solicita tu asesoría hoy!"
        />
      </Head>
      <div className="flex flex-col md:flex-row justify-between items-center w-full gap-10 lg:gap-20 px-10 md:px-16 xl:px-32 z-10">
        <LayoutText />
        <LoginForm />
      </div>
    </LoginLayout>
  );
}
