/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useRouter } from 'next/router';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { useCountry } from '../../../hooks/useCountry';
import SelectCountryModal from '../../modals/SelectCountryModal';
import Anchor from '../../common/Anchor';
import CountrySelector from '../navbar/CountrySelector';

export default function LoginNavbar() {
  const router = useRouter();
  const { currentCountry, countryPhoneData } = useCountry();

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (currentCountry) return;
    else setIsOpen(true);
  }, [currentCountry]);

  return (
    <>
      <div className="absolute w-full flex items-center justify-between text-white h-16 px-4 py-3 md:h-24 md:px-14 md:py-7">
        <Anchor
          href="https://widulegal.com/ve/"
          className="h-full flex items-center justify-center"
        >
          <figure className="h-2/3">
            <img
              alt="Logo Widú Legal"
              src="/logos/white-logo.png"
              className="hidden md:flex h-full w-auto"
            />
            <img
              alt="Logo Widú Legal"
              src="/logos/white-widu-icon.png"
              className="md:hidden h-full w-auto"
            />
          </figure>
        </Anchor>
        <div className="flex items-center gap-5 lg:gap-7 text-sm lg:text-base">
          <Anchor href={`https://wa.me/${countryPhoneData?.wa}`}>
            <div className="flex items-center gap-2">
              <PhoneIcon className="w-6 h-6 fill-white" />
              <span className="font-semibold">{countryPhoneData?.phone}</span>
            </div>
          </Anchor>

          {router.pathname !== '/register' && (
            <button
              type="button"
              onClick={() => router.push('/register')}
              className="font-semibold border border-white rounded-lg py-2 px-4 hover:cursor-pointer hidden md:flex hover:bg-white hover:text-primary-300 transition-all duration-300 ease-in-out"
            >
              Registrarse
            </button>
          )}

          <CountrySelector onClick={() => setIsOpen(true)} />
        </div>
      </div>
      <SelectCountryModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
