/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Anchor from '../common/Anchor';
import TwitterXIcon from '../icons/TwitterXIcon';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedinIcon from '../icons/LinkedinIcon';

function LayoutText() {
  const router = useRouter();

  return (
    <section className="hidden md:flex flex-col gap-10 w-fit text-white">
      <button
        type="button"
        className="flex gap-2 items-center font-medium"
        onClick={() => router.push('https://widulegal.com/ve/')}
      >
        <ChevronLeftIcon className="w-6 h-6" />
        Volver al Inicio
      </button>
      <img
        src="/logos/white-widu-icon.png"
        alt="Logo Widú Legal"
        className="w-32"
      />
      <p className="font-bold text-white text-3xl text-pretty">
        Bienvenido a Widú legal
      </p>
      <div className="flex gap-5 items-center">
        <Anchor href="https://twitter.com/WiduLegal">
          <TwitterXIcon className="w-6 h-6" />
        </Anchor>
        <Anchor href="https://www.linkedin.com/company/widu-legal/">
          <LinkedinIcon className="w-6 h-6" />
        </Anchor>
        <Anchor href="https://www.facebook.com/widulegal">
          <FacebookIcon className="w-6 h-6" />
        </Anchor>
        <Anchor href="https://instagram.com/widulegal?igshid=YmMyMTA2M2Y=">
          <InstagramIcon className="w-6 h-6" />
        </Anchor>
      </div>
    </section>
  );
}

export default LayoutText;
